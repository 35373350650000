import NextStepButton from '../../../components/next-step-button/arrow-right';

function TellAge(props) {

    const userAgeChange = (event) => {
        props.userAgeChange(event.target.value);
    };
    
    return (
        <>
            <h2 className='text-2xl font-normal'>Alles klar {props.userFName}! Um ein guter wingman zu sein, brauche ich dein Alter:</h2>
            <div className='flex align-center gap-2 mt-4'>
                <input required value={props.userAge} onChange={userAgeChange} type="number" placeholder="22" class="input input-bordered w-full max-w-xs" />
                <NextStepButton onClick={props.nextIntroStep}></NextStepButton>
            </div>
        </>
    );
}

export default TellAge;
