import NextStepButton from '../../../components/next-step-button/arrow-right';

function PartnerNameInput(props) {
  const handlePartnerNameChange = (event) => {
    props.onPartnerNameChange(event.target.value);
  };



  return (
    <>
      <h2 className='text-2xl font-normal'>Interessant! Bitte nenne mir den Vornamen der Person, mit der du gerade schreibst:</h2>
      <br/>
      <div className='flex align-center gap-2'>
        <input value={props.partnerNameValue} onChange={handlePartnerNameChange} type="text" placeholder="z.B.: Sandra" class="input input-bordered w-full" />
        <NextStepButton onClick={props.nextIntroStep}></NextStepButton>
      </div>
    </>
  );
}

export default PartnerNameInput;
