import NextStepButton from '../../../components/next-step-button/arrow-right';

function PartnerKnowFrom(props) {

  const changePartnerBackgroundValue = (event) => {
    props.setPartnerBackgroundValue(event.target.value);
  };

  return (
      <>
        <h2 className='text-2xl font-normal mb-4'>Woher kennst du {props.partnerNameValue}? Erzähl mir in ein bis zwei Sätzen über euren Hintergrund:</h2>
        <div className='display flex gap-2'>
          <textarea class="textarea textarea-bordered w-full leading-6 text-base" onChange={changePartnerBackgroundValue} value={props.partnerBackgroundValue} rows={4} placeholder={`z.B.: Wir sind beide aus Aachen, hatten ein Tindermatch und haben dann etwas geschrieben. Sie macht eine Ausbildung zur Maklerin und schein sehr locker und offen für neues zu sein.`}></textarea>
          <NextStepButton onClick={props.nextIntroStep}></NextStepButton>
        </div>
      </>
  );
}

export default PartnerKnowFrom;
