import { useState } from 'react';
import axios from 'axios';
import Tesseract from 'tesseract.js';

function UploadChat(props) {

    const [chatOpen, setChatOpen] = useState(false);
    
    const openChat = () => {
        setChatOpen(true);
    }

    const handleImageUpload = (event) => {
        const imageFile = event.target.files[0];
        
        openChat();
        
        Tesseract.recognize(imageFile, 'eng')
        .then((result) => {
            props.setChatText(result.data.text);
            console.log(result.data.text);

            const getRes = () => {

                axios({
                    method: "POST",
                    url: "https://api.openai.com/v1/chat/completions",
                    data: {
                        model: "gpt-3.5-turbo",
                        temperature: 0.8,
                        top_p: 0.6,
                        n: 1,
                        messages: [
                            {
                                role: "system",
                                content: `Du bist ein intelligenter Textfilter. Es wurde mit Hilfe von OCR ein vollständiger Screenshot von einem Smartphone, der einen Chat enthält, ausgelesen. Das Ergebnis beinhaltet dementsprechend auch andere Elemente die in dem Screenshot enthalten waren und nicht zum reinen Chatverlauf gehören. Du sollst jetzt die einzelnen Nachrichten, die zum tatsächlichen Chatverlauf gehören, herausfiltern und einzeln abgetrennt im JSON-Format auflisten. Gebe nie Timestamps oder Absender an, sondern immer nur die reinen Chat-Nachrichten! Mache keine Erklärungen. Führe alle Nachrichten einzeln auf! Ein Satz ist immer auch eine einzelne Nachricht, Fasse also nie mehrere Sätze in einer Nachricht zusammen! Auch einzelne Wörter können eine Nachricht sein! Alles was im Kontext der Nachrichten keinen Sinn ergibt, nicht zu einem Satz aus dem Chat gehört sowie für die deutsche Sprache und den Kontext verwirrende bzw. unsinnige Zeichen-, Buchstaben- und/oder Zahlenkombinationen, sollst du nicht wiedergeben. Deutsche Umlaute wie ä, ö, ü wurden vom OCR nicht erkannt und fälschlicherweise einfache Vokale verwendet, andere Buchstaben genutzt oder ein Akzentzeicchen verwendet. Korrigiere Wörter, die offensichtlich falsch ausgelesene Umlaute enthalten und verwende dort die richtige deutsche Rechtschreibung! gib mir diese in einem einfachen Array im JSON Format zurück ["","",""]. Sende mir nur die JSON zurück ohne weitere Anmerkungen. Anbei der OCR:`
                            },
                            {
                                role: 'system',
                                content: result.data.text
                            }
                        ]
                    },
                    // Es wurde mithilfe eines OCR ein Chat Screenshot ausgelesen. Dieser gibt nur ein Textblock zurück. Bitte identifiziere die gesendeten Nachrichten und gib mir diese in einem einfachen Array im JSON Format zurück ["","",""]. Bitte entferne jegliche Sonderzeichen, Button Namen die nicht in den Rest des Satzen passen und wahrscheinlich falsch vom OCR ausgelesen wurden. Und sehr wichtig, Sende mir nur die JSON zurück ohne weitere Anmerkungen. Hier der OCR Inhalt:\n${result.data.text}
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                        "Bearer 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"
                    }
                })
                .then((res) => {
                    if(res.status === 200) {
                        const extractText = res.data.choices[0].message.content;
                        const extractTextArray = JSON.parse(extractText);
                        props.setChatTextArray(extractTextArray);
                        console.log('Chat Text Array',extractTextArray);
                        props.nextIntroStep();
                    }
                })
                .catch((e) => {
                    console.log(e.message, e);
                });
            };
            const responseHandler = (res) => {
                if (res.status === 200) {
                    console.log(res.data.choices);
                    props.setResponse(res.data.choices);
                    props.nextIntroStep();
                }
            };
            getRes();
        })
        .catch((error) => {
            console.error(error);
        });
    };

    return (
        <>
            <h2 className='text-2xl font-normal mb-6'>Dabei kann meine KI dir definitiv helfen! Jetzt musst du nur noch einen Screenshot vom Chat hochladen, dann kann ich dir sagen was du am besten antworten solltest!</h2>
            {!chatOpen && <input type="file" class="file-input file-input-bordered w-full max-w-xs file-input-fila-red" onChange={handleImageUpload}/>}
            {
                chatOpen && <button class="btn bg-flia-red text-white">
                    <span class="loading loading-spinner"></span>
                    {!props.chatText && <p>Auslesen</p>}
                    {props.chatText && <p>Analysieren</p>}
            </button>}
        </>
    );
}

export default UploadChat;
