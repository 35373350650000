import React from "react";
import { useNavigate } from "react-router-dom";
import './page.css';

import Logo from "../components/logo/logo";

function WelcomeIntroduction() {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  const navigateToSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="App">
      <header className="App-header">
        <Logo fontSize={5}></Logo>
        <p className="font text-lg mt-[-.4rem] text-flia-gray">The wing(wo)man for your pocket.</p>
        <button className="btn btn-sm text lowercase border-solid mb-2 mt-8 border-2 border-flia-red rounded-full bg-white px-4 text-xl" onClick={navigateToLogin}>login</button>
        <button className="text-flia-red text-lg mt-[-.4rem] hidden" onClick={navigateToSignUp}>or sign up for free</button>
      </header>
    </div>
  );
}

export default WelcomeIntroduction;
