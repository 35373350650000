import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import WelcomeIntroduction from "./welcome/page";

import SignUpForm from "./signup/page";
import TermsPDF from './terms.pdf';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WelcomeIntroduction></WelcomeIntroduction>} />
        <Route path="/login" element={<Navigate to="/signup" replace />} />
        <Route path="/signup" element={<SignUpForm></SignUpForm>} />
        <Route path="/agb" element={<embed src={TermsPDF} type="application/pdf" width="100%" height="800px" />}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
