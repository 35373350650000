import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './page.css';

import Logo from '../components/logo/logo';

import EnterName from "../start/pages/01-welcome-name/page";
import TellAge from '../start/pages/02-tell-age/page';
import TwoSentences from '../start/pages/03-two-sentences-about/page';
import EmailInput from '../start/pages/00-email-input/page';
import PartnerNameInput from '../start/pages/04-partner-name/page';
import PartnerKnowFrom from '../start/pages/05-partner-know-from/page';
import PartnerIntention from '../start/pages/06-partner-intention/page';
import UploadChat from '../start/pages/07-i-can-help-you/page';
import SortTheMessages from '../start/pages/08-align-the-chats/page';
import AIOptions from '../start/pages/09-ai-options/page';

function useLocalStorageState(key, defaultValue = "", setting) {
    const [state, setState] = useState(() => {
        const storedValue = localStorage.getItem(key);
        if(typeof defaultValue === 'number') {
            let out = storedValue ? parseInt(storedValue) : defaultValue;
            if(typeof setting.maxVal === 'number' && out > setting.maxVal) {
                out = setting.maxVal;
            }
            return out;
        }
        return storedValue ? storedValue : defaultValue;
    });
    
    useEffect(() => {
        localStorage.setItem(key, state);
    }, [key, state]);
    
    return [state, setState];
}

function SignUpForm() {
    const [page, setPage] = useLocalStorageState("page", 1, {maxVal: 4});

    const [userMail, setMailValue] = useLocalStorageState("userMail");
    const [userFName, setFNameValue] = useLocalStorageState("userFName");
    const [userAge, setAgeValue] = useLocalStorageState("userAge");
    const [userInfo, setUserInfo] = useLocalStorageState("userInfo");

    const [partnerNameValue, setPartnerNameValue] = useState('');
    const [partnerBackgroundValue, setPartnerBackgroundValue] = useState('');
    const [responses, setResponse] = useState([]);
    const [chatText, setChatText] = useState('');
    const [chatTextArray, setChatTextArray] = useState('');
    const [finishedChatTextArray, setFinishedChatTextArray] = useState([]);

    const navigate = useNavigate();

    const handlePartnerNameChange = (value) => {
        setPartnerNameValue(value);
    };
    const handleResponseChange = value => {
        setResponse(value);
    }

    const pageForwards = () => {
        setPage(page + 1);
    };
    
    const pageBackwards = () => {
        setPage(page - 1);
        if(page <= 1) {
            navigate('/');
        }
    };

    const resetPartner = () => {
        setPage(5);
    }

    const standardVariables = {
        nextIntroStep: pageForwards,
    };

    if(!(page > 0 && page <= 10)) {
        setPage(1);
    }

    const components = [
        null,
        <EmailInput {...standardVariables} userMailChange={setMailValue} userMail={userMail} />,
        <EnterName {...standardVariables} userFNameChange={setFNameValue} userFName={userFName} />,
        <TellAge {...standardVariables} userAgeChange={setAgeValue} userFName={userFName} userAge={userAge} />,
        <TwoSentences {...standardVariables} setUserInfo={setUserInfo} userInfo={userInfo} userFName={userFName} />,
        <PartnerNameInput {...standardVariables} userFName={userFName} partnerNameValue={partnerNameValue} onPartnerNameChange={handlePartnerNameChange} />,
        <PartnerKnowFrom {...standardVariables} userFName={userFName} partnerNameValue={partnerNameValue} partnerBackgroundValue={partnerBackgroundValue} setPartnerBackgroundValue={setPartnerBackgroundValue} />,
        <PartnerIntention {...standardVariables} userFName={userFName} partnerNameValue={partnerNameValue} />,
        <UploadChat {...standardVariables} userFName={userFName} chatTextArray={chatTextArray} setChatTextArray={setChatTextArray} userAge={userAge} chatText={chatText} setChatText={setChatText} setResponse={handleResponseChange} partnerNameValue={partnerNameValue} partnerBackgroundValue={partnerBackgroundValue} />,
        <SortTheMessages {...standardVariables} setPage={setPage} page={page} userFName={userFName} chatTextArray={chatTextArray} setChatTextArray={setChatTextArray} setFinishedChatTextArray={setFinishedChatTextArray} userAge={userAge} chatText={chatText} setChatText={setChatText} setResponse={handleResponseChange} partnerNameValue={partnerNameValue} partnerBackgroundValue={partnerBackgroundValue} />,
        <AIOptions {...standardVariables} setPage={setPage} page={page} userFName={userFName} userAge={userAge} userInfo={userInfo} finishedChatTextArray={finishedChatTextArray} setChatTextArray={setChatTextArray} responses={responses} setResponse={setResponse} chatText={chatText} partnerNameValue={partnerNameValue} partnerBackgroundValue={partnerBackgroundValue} resetPartner={resetPartner} retry={pageBackwards} />
    ];

    const renderStep = () => components[page];

    return (
        <>
            <div className="wrapper w-full h-full grid place-items-center">
                <div className="container flex flex-col max-w-md gap-4 py-2 px-4 h-full max-h-[660px] justify-between">
                    <Logo fontSize={1.5} className='mr-full'></Logo>
                    <div className="action mb-4">
                        {renderStep()}
                    </div>
                    <div className='flex flex-col gap-4'>
                        {page <= 8 && <p className="m-auto">{page} / 9</p>}
                        <div className="flex flex-row justify-between">
                            {page >= 1 && page <= 9 && <button className="text-flia-red" onClick={pageBackwards}>&lt; go back</button>}
                            {page >= 2 && page <= 4 && <button className="text-flia-red hidden" onClick={pageForwards}>skip and just give me tips &gt;</button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUpForm;