import React from "react";

function Logo(props) {
    let font = props.fontSize ? props.fontSize : 3;
    
    return(
    <div className="flex items-center justify-center mb-2 gap-1 w-fit">
        <h1 style={{fontSize: `${font}rem`}}>flia</h1>
        <svg width={`${font+2}rem`} className="m-[-.2rem] -mr-[.4rem] -mb-[.4rem] -ml-[.4rem]" viewBox="110 00 200 125" version="1.1">
            <defs id="defs402">
            <clipPath id="clip0">
                <rect x="147" y="151" width="1239" height="697" id="rect526" />
            </clipPath>
            <clipPath id="clip1">
                <rect x="895" y="209" width="491" height="440" id="rect529" />
            </clipPath>
            <clipPath id="clip2">
                <rect x="895" y="209" width="491" height="440" id="rect532" />
            </clipPath>
            <clipPath id="clip3">
                <rect x="895" y="209" width="491" height="440" id="rect535" />
            </clipPath>
            <clipPath id="clip4">
                <rect x="895" y="209" width="491" height="440" id="rect538" />
            </clipPath>
            </defs>
            <g id="layer1" transform="translate(58.9316,-83.657497)">
            <g
                clip-path="url(#clip0)"
                transform="matrix(0.26458333,0,0,0.26458333,-152.61553,28.344236)"
                id="g625"
            >
                <g clip-path="url(#clip1)" id="g621">
                <g clip-path="url(#clip2)" id="g619">
                    <g clip-path="url(#clip3)" id="g617">
                    <g clip-path="url(#clip4)" id="g615">
                        <path
                        d="m 709.881,172.953 c 0,-11.197 9.139,-20.274 20.413,-20.274 11.273,0 20.412,9.077 20.412,20.274 0,11.198 -9.139,20.275 -20.412,20.275 -11.274,0 -20.413,-9.077 -20.413,-20.275 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path543"
                        />
                        <path
                        d="m 1083.1,172.678 c 0,-11.198 9.07,-20.275 20.27,-20.275 11.2,0 20.28,9.077 20.28,20.275 0,11.197 -9.08,20.274 -20.28,20.274 -11.2,0 -20.27,-9.077 -20.27,-20.274 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path545"
                        />
                        <path
                        d="m 897.178,259.568 c 0,-11.197 9.078,-20.274 20.275,-20.274 11.196,0 20.274,9.077 20.274,20.274 0,11.197 -9.078,20.274 -20.274,20.274 -11.197,0 -20.275,-9.077 -20.275,-20.274 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path547"
                        />
                        <path
                        d="m 821.322,160.816 c 0,-11.197 9.138,-20.274 20.412,-20.274 11.274,0 20.412,9.077 20.412,20.274 0,11.198 -9.138,20.275 -20.412,20.275 -11.274,0 -20.412,-9.077 -20.412,-20.275 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path549"
                        />
                        <path
                        d="m 971.104,161.368 c 0,-11.197 9.078,-20.274 20.275,-20.274 11.201,0 20.271,9.077 20.271,20.274 0,11.197 -9.07,20.274 -20.271,20.274 -11.197,0 -20.275,-9.077 -20.275,-20.274 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path551"
                        />
                        <path
                        d="m 672.642,282.187 c 0,-11.197 9.139,-20.274 20.413,-20.274 11.273,0 20.412,9.077 20.412,20.274 0,11.197 -9.139,20.275 -20.412,20.275 -11.274,0 -20.413,-9.078 -20.413,-20.275 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path553"
                        />
                        <path
                        d="m 1121.44,282.187 c 0,-11.197 9.14,-20.274 20.41,-20.274 11.27,0 20.41,9.077 20.41,20.274 0,11.197 -9.14,20.275 -20.41,20.275 -11.27,0 -20.41,-9.078 -20.41,-20.275 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path555"
                        />
                        <path
                        d="m 746.844,417.764 c 0,-11.274 9.078,-20.413 20.275,-20.413 11.196,0 20.274,9.139 20.274,20.413 0,11.273 -9.078,20.412 -20.274,20.412 -11.197,0 -20.275,-9.139 -20.275,-20.412 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path557"
                        />
                        <path
                        d="m 1047.51,417.764 c 0,-11.274 9.08,-20.413 20.28,-20.413 11.19,0 20.27,9.139 20.27,20.413 0,11.273 -9.08,20.412 -20.27,20.412 -11.2,0 -20.28,-9.139 -20.28,-20.412 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path559"
                        />
                        <path
                        d="m 897.178,558.581 c 0,-11.196 9.078,-20.274 20.275,-20.274 11.196,0 20.274,9.078 20.274,20.274 0,11.197 -9.078,20.275 -20.274,20.275 -11.197,0 -20.275,-9.078 -20.275,-20.275 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path561"
                        />
                        <path
                        d="m 753.188,233.087 c 0,-11.197 9.078,-20.274 20.275,-20.274 11.196,0 20.274,9.077 20.274,20.274 0,11.197 -9.078,20.275 -20.274,20.275 -11.197,0 -20.275,-9.078 -20.275,-20.275 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path563"
                        />
                        <path
                        d="m 867.387,363.423 c 0,-11.274 9.139,-20.413 20.413,-20.413 11.273,0 20.412,9.139 20.412,20.413 0,11.273 -9.139,20.412 -20.412,20.412 -11.274,0 -20.413,-9.139 -20.413,-20.412 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path565"
                        />
                        <path
                        d="m 1003.1,279.98 c 0,-11.197 9.14,-20.274 20.41,-20.274 11.28,0 20.42,9.077 20.42,20.274 0,11.197 -9.14,20.275 -20.42,20.275 -11.27,0 -20.41,-9.078 -20.41,-20.275 z"
                        stroke="#860000"
                        stroke-width="1.26428"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path567"
                        />
                        <path
                        d="M 0,0 37.2504,109.55"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,-1.0007,916.102,351.614)"
                        id="path569"
                        />
                        <path
                        d="M 0,0 111.627,10.8634"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1064.69,229.013)"
                        id="path571"
                        />
                        <path
                        d="M 916.239,259.462 840.906,159.851"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path573"
                        />
                        <path
                        d="M 0,0 72.7742,98.7707"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,-1.0007,1141.19,328.68)"
                        id="path575"
                        />
                        <path
                        d="M 1104.15,170.714 994.275,159.851"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path577"
                        />
                        <path
                        d="m 1103.78,172.815 37.35,110.862"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path579"
                        />
                        <path
                        d="M 0,0 73.493,137.698"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1364.19,352.676)"
                        id="path581"
                        />
                        <path
                        d="M 0,0 150.912,141.956"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1290.72,487.105)"
                        id="path583"
                        />
                        <path
                        d="M 916.725,559.721 768.084,417.764"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path585"
                        />
                        <path
                        d="M 768.266,417.885 692.503,282.325"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path587"
                        />
                        <path
                        d="M 0,0 79.6443,46.8891"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,997.953,303.818)"
                        id="path589"
                        />
                        <path
                        d="M 0,0 7.78776,184.005"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,997.263,302.99)"
                        id="path591"
                        />
                        <path
                        d="M 0,0 106.894,278.678"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1246.15,350.192)"
                        id="path593"
                        />
                        <path
                        d="M 0,0 122.373,53.0506"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1111.3,432.174)"
                        id="path595"
                        />
                        <path
                        d="m 891.386,363.423 26.003,197.655"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path597"
                        />
                        <path
                        d="M 990.965,160.678 1023.01,279.63"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path599"
                        />
                        <path
                        d="M 0,0 81.7636,107.078"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,-1.0007,1247.11,349.14)"
                        id="path601"
                        />
                        <path
                        d="M 0,0 28.6487,102.522"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1139.22,329.765)"
                        id="path603"
                        />
                        <path
                        d="M 773.877,232.949 888.87,363.919"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path605"
                        />
                        <path
                        d="M 0,0 135.238,84.4079"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1246.64,347.984)"
                        id="path607"
                        />
                        <path
                        d="m 1141.71,281.699 -118.75,-1.857"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path609"
                        />
                        <path
                        d="M 0,0 69.7945,73.1397"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(-1,0,0,1.0007,1066.72,229.013)"
                        id="path611"
                        />
                        <path
                        d="M 1067.02,416.442 1024.07,278.739"
                        stroke="#860000"
                        stroke-width="15.1713"
                        stroke-miterlimit="8"
                        fill="#860000"
                        fill-rule="evenodd"
                        transform="matrix(1,0,0,1.0007,223.047,69.0502)"
                        id="path613"
                        />
                    </g>
                    </g>
                </g>
                </g>
                {/* <text
        font-family="'Poppins Light', 'Poppins Light_MSFontService', sans-serif"
        font-weight="300"
        font-size="332px"
        transform="translate(346.777,556)"
        id="text623">flia</text> */}
            </g>
            </g>
        </svg>
        </div>
    );
}

export default Logo;