import NextStepButton from '../../../components/next-step-button/arrow-right';

function EmailInput(props) {
  const userMailChange = (event) => {
    props.userMailChange(event.target.value);
  };



  const acceptTerms = () => {
    const termsAccepted = localStorage.getItem('termsAccepted');
    if (termsAccepted === 'true') {
      props.nextIntroStep();
    } else {
      window.termsOfUseModal.showModal();
    }
  }

  const acceptedTerms = () => {
    localStorage.setItem('termsAccepted', 'true');
    props.nextIntroStep();
  }

  return (
    <>
      <h2 className='text-2xl font-normal'>Melde dich mit deiner E-Mail an:</h2>
      <br/>
      <div className='flex align-center gap-2'>
        <input value={props.userMail} onChange={userMailChange} type="text" placeholder="yourmail@mail.com" class="input input-bordered w-full max-w-xs" />
        <NextStepButton onClick={acceptTerms}></NextStepButton>
      </div>

      {/* Dialogue */}
      <dialog id="termsOfUseModal" class="modal modal-bottom sm:modal-middle">
        <form method="dialog" class="modal-box">
          <h3 class="text-fila-red font-bold text-lg">Datenschutz</h3>
          <p class="py-4">Stelle immer sicher, dass deine Screenshots und Angaben keine personenbezogenen Daten von dritten enthalten. Dies sind Namen, Adressen, Telefonnummer und andere Informationen, die zur Identifikation einer Person führen können. Für mehr Informationen schaue in unsere <a className='underline' href="agb" target='_blank'>AGB‘s</a>.</p>
          <div class="modal-action">
            <button class="btn">Zurük</button>
            <button class="btn bg-flia-red text-white hover:bg-fila-red" onClick={acceptedTerms}>Akzeptieren</button>
          </div>
        </form>
      </dialog>
    </>
  );
}

export default EmailInput;
