import { useState } from 'react';

function SortTheMessages(props) {

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckboxChange = (index, option) => {
        setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = [...prevSelectedOptions];
            updatedOptions[index] = option;
            return updatedOptions;
        });
    };

    let messages = [];
    if(props.chatTextArray) {
        messages = props.chatTextArray.map((text, index) => ({
            role: selectedOptions[index] === 'start' ? 'user' : 'assistant',
            content: text,
        }))
    } else {
        props.setPage(props.page - 1);
    }
    
    const handleSubmit = () => {
        props.setFinishedChatTextArray(messages);
        props.nextIntroStep();
    }

    return (
        <>
            <h2 className='text-2xl font-normal mb-6'>Ordne die Nachrichten zu</h2>
            <div className='flex justify-between text-flia-red'>
                <p>{props.partnerNameValue}</p>
                <p>Du ({props.userFName})</p>
            </div>
            <div>
            {messages.map((message, index) => (
                <div className='di flex gap-2 justify-center items-center' key={index}>
                    <input
                        type="radio"
                        name={`radio-${index}`}
                        className="radio radio-[flia-red]"
                        checked={selectedOptions[index] === 'start'}
                        onChange={() => handleCheckboxChange(index, 'start')}
                    />
                    <div className={`chat ${selectedOptions[index] === 'start' ? 'chat-start' : 'chat-end'} w-full`}>
                        <div className="chat-bubble bg-slate-200 text-black">{message.content}</div>
                    </div>
                    <input
                        type="radio"
                        name={`radio-${index}`}
                        className="radio radio-[flia-red]"
                        checked={selectedOptions[index] === 'end'}
                        onChange={() => handleCheckboxChange(index, 'end')}
                    />
                </div>
            ))}
            </div>
            {/* <p className='mt-2 text-xs'>{props.chatText}</p> */}
            <div className='flex justify-center'>
                <button className="btn bg-flia-red text-white mt-3" onClick={handleSubmit}>Fertig</button>
            </div>
        </>
    );
}

export default SortTheMessages;
