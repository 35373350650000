import NextStepButton from '../../../components/next-step-button/arrow-right';

function TwoSentences(props) {

    const setUserInfo = (event) => {
      props.setUserInfo(event.target.value);
    };

    return (
        <>
        <h2 className='text-2xl font-normal mb-2'>Cool! Jetzt stelle dich in ein bis zwei Sätzen kurz vor:</h2>
        <div className='display flex gap-2'>
          <textarea onChange={setUserInfo} value={props.userInfo} className="textarea textarea-bordered w-full leading-6 text-base" rows={4} placeholder="z.B.: Ich studiere in Aachen Psychologie und gehe zur Abwechslung gerne ins Gym oder treffe mich mit Freunden auf einen Drink. Ich bin ein lockerer, extrovertierter Typ und liebe Abenteuer."></textarea>
          <NextStepButton onClick={props.nextIntroStep}></NextStepButton>
        </div>
        </>
    );
}

export default TwoSentences;
