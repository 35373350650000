import { useState } from 'react';

import NextStepButton from '../../../components/next-step-button/arrow-right';

function PartnerIntention(props) {

    const [intention, setIntention] = useState('');

    const handleIntention = event => {
        setIntention(event.target.value)
    }

    const submit = (event) => {
        // console.log(event.target.value)
        // setIntention();
        props.nextIntroStep();
    }

    return (
        <>
            {intention};
            <h2 className='text-2xl font-normal mb-2'>Was sind deine Ziele mit {props.partnerNameValue}?</h2>
            <button onClick={submit} value={`${props.partnerNameValue} ist echt cool drauf und ich möchte sie/ihn erstmal näher kennenlernen ✌🏼`} className="btn w-full text-sm border-solid mt-2 border-2 border-flia-red rounded-full bg-white px-4 py-2 h-fit text-1xl normal-case" >{props.partnerNameValue} ist echt cool drauf und ich möchte sie/ihn erstmal näher kennenlernen ✌🏼</button>
            <button onClick={submit} value={'Ich finde echt attraktiv und möchte ihr/sein Interesse an mir wecken 😍'} className="btn w-full text-sm border-solid mt-2 border-2 border-flia-red rounded-full bg-white px-4 py-2 h-fit text-1xl normal-case">Ich finde echt attraktiv und möchte ihr/sein Interesse an mir wecken 😍</button>
            <input onChange={handleIntention} value={intention} className="btn w-full text-sm border-solid mt-2 border-2 border-flia-red rounded-full bg-white px-4 py-2 h-fit text-1xl normal-case" placeholder='Schreibe etwas eigenes...'></input>
            {intention && <NextStepButton className="mt-2 w-full" onClick={props.nextIntroStep}></NextStepButton>}
        </>
    );
}

export default PartnerIntention;
