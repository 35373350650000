import NextStepButton from '../../../components/next-step-button/arrow-right';

function EnterName(props) {
  const userFNameChange = (event) => {
    props.userFNameChange(event.target.value);
  };
  
  return (
    <>
      <h2 className='text-2xl font-normal'>Willkommen bei flia - dein Flirt Assistant.<br/>Wie heisst du?</h2>
      <br/>
      <div className='flex align-center gap-2'>
        <input required value={props.userFName} onChange={userFNameChange} type="text" placeholder="z.B.: Roman" class="input input-bordered w-full max-w-xs" />
        <NextStepButton onClick={props.nextIntroStep}></NextStepButton>
      </div>
    </>
  );
}

export default EnterName;
