import React from "react";

import ArrowRight from "../arrow-right/arrow-right";

function NextStepButton(props) {
    return(
        <button className={`btn btn-square h-auto ${props.className}`} onClick={props.onClick}>
            <ArrowRight></ArrowRight>
        </button>
    )
};

export default NextStepButton;